import React from 'react';
import PropTypes from 'prop-types';

const Chip = ({ recentSearch, btnLabel, removeLabel, onRemove, onReorder }) => {
	const handleRemove = (e) => {
		e.stopPropagation();
		onRemove(recentSearch.id);
	};

	const handleClick = () => {
		onReorder(recentSearch);
	};

	const truncatedValue =
		recentSearch.value.length > 20
			? `${recentSearch.value.substring(0, 20)}...`
			: recentSearch.value;

	return (
		<button
			className="chip chip-default mr-3 mt-3"
			type="button"
			role="menuitem"
			aria-label={`${btnLabel}: ${recentSearch.value}`}
			tabIndex={0}
			onClick={handleClick}
		>
			{truncatedValue}
			<span
				role="button"
				tabIndex={0}
				className="ddc-icon ddc-icon-remove2 icon-size-2 ml-2"
				onClick={handleRemove}
				onKeyDown={(e) => {
					if (e.key === 'Enter' || e.key === ' ') {
						handleRemove(e);
					}
				}}
				aria-label={`${removeLabel}: ${recentSearch.value}`}
				data-testid="remove-icon"
			/>
		</button>
	);
};

Chip.propTypes = {
	recentSearch: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string
	}),
	btnLabel: PropTypes.string,
	removeLabel: PropTypes.string,
	onRemove: PropTypes.func,
	onReorder: PropTypes.func
};

export default Chip;
