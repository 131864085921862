/* eslint-disable max-len */
export const mockInventoryData = {
	suggestions: [
		{
			suggestion: 'Honda Civic',
			facetInfo: {
				make: 'Honda',
				model: 'Civic'
			},
			count: 11,
			path: '/index.htm'
		},
		{
			suggestion: 'Honda Civic Si',
			facetInfo: {
				make: 'Honda',
				model: 'Civic Si'
			},
			count: 1
		},
		{
			suggestion: 'Honda Civic EX',
			facetInfo: {
				make: 'Honda',
				model: 'Civic',
				trim: 'EX'
			},
			count: 2
		},
		{
			suggestion: 'Honda Civic EX W/BSI',
			facetInfo: {
				make: 'Honda',
				model: 'Civic',
				trim: 'EX w/BSI'
			},
			count: 1
		},
		{
			suggestion: 'Honda Civic LX',
			facetInfo: {
				make: 'Honda',
				model: 'Civic',
				trim: 'LX'
			},
			count: 4
		},
		{
			suggestion: 'Honda Civic Sport',
			facetInfo: {
				make: 'Honda',
				model: 'Civic',
				trim: 'Sport'
			},
			count: 2
		},
		{
			suggestion: 'Honda Civic Sport Touring',
			facetInfo: {
				make: 'Honda',
				model: 'Civic',
				trim: 'Sport Touring'
			},
			count: 1
		},
		{
			suggestion: 'Honda Civic Touring',
			facetInfo: {
				make: 'Honda',
				model: 'Civic',
				trim: 'Touring'
			},
			count: 1
		}
	],
	vehicles: [
		{
			path: '/certified/Honda/2020-Honda-Civic-b3f6fde40a0e087f2ab124427ec98471.htm',
			title: ['2020 Honda', 'Civic EX'],
			images: [
				{
					url: '//images.dealer.com/ddc/vehicles/2020/Honda/Civic/Sedan/trim_EX_53bf5a/color/Modern%20Steel%20Metallic-GY-64%2C63%2C63-640-en_US.jpg'
				}
			],
			price: '$24,900',
			odometer: '666',
			odometerUnit: 'miles',
			condition: 'used'
		},
		{
			path: '/certified/Honda/2020-Honda-Civic-b3f767cf0a0e087f2ab12442bfff2ffd.htm',
			title: ['2020 Honda', 'Civic EX'],
			images: [
				{
					url: '//pictures.qa.dealer.com/w/wsmycarsdemo/1942/2d55c3dbe724ac6de47568676bb9de5dx.jpg'
				},
				{
					url: '//pictures.qa.dealer.com/w/wsmycarsdemo/0086/bd96eeb3825b5c338dc2bdf1f6f0ed63x.jpg'
				}
			],
			price: '$24,900',
			odometer: '666',
			odometerUnit: 'miles',
			condition: 'used'
		},
		{
			path: '/new/Honda/2023-Honda-Civic-b3f7588c0a0e087f2ab12442d9ca9966.htm',
			title: ['2023 Honda', 'Civic EX w/BSI'],
			images: [
				{
					url: '//images.dealer.com/ddc/vehicles/2023/Honda/Civic/Sedan/trim_EX_c3cafd/color/Lunar%20Silver%20Metallic-SI-148%2C147%2C147-640-en_US.jpg'
				}
			],
			price: '$24,900',
			odometer: '6',
			odometerUnit: 'miles',
			condition: 'new'
		},
		{
			path: '/new/Honda/2023-Honda-Civic-b3f6f1930a0e094a32a4d1c1edf335f0.htm',
			title: ['2023 Honda', 'Civic LX'],
			images: [
				{
					url: '//images.dealer.com/ddc/vehicles/2023/Honda/Civic/Sedan/trim_LX_3c4a47/color/Rallye%20Red-RE-153%2C33%2C33-640-en_US.jpg'
				}
			],
			price: '$24,900',
			odometer: '1',
			odometerUnit: 'miles',
			condition: 'new'
		},
		{
			path: '/new/Honda/2023-Honda-Civic-b3f718d90a0e087f2ab1244231c34c03.htm',
			title: ['2023 Honda', 'Civic LX'],
			images: [
				{
					url: '//images.dealer.com/ddc/vehicles/2023/Honda/Civic/Hatchback/trim_LX_db10b1/color/Platinum%20White%20Pearl-WX-235%2C234%2C231-640-en_US.jpg'
				}
			],
			price: '$24,900',
			odometer: '',
			odometerUnit: 'miles',
			condition: 'new'
		},
		{
			path: '/certified/Honda/2020-Honda-Civic-b3f6ec680a0e094a32a4d1c1182ca530.htm',
			title: ['2020 Honda', 'Civic LX'],
			images: [
				{
					url: '//pictures.qa.dealer.com/w/wsmycarsdemo/1491/5303d66865673155e511ece7f4892bc1x.jpg'
				},
				{
					url: '//pictures.qa.dealer.com/w/wsmycarsdemo/0759/c203e17897479b2b5fe3e7d5a12961e5x.jpg'
				}
			],
			price: '$24,900',
			odometer: '666',
			odometerUnit: 'miles',
			condition: 'used'
		}
	],
	searchTerm: 'civic'
};
