import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { setClassNames } from 'ddc-classnames-js';
import { useLabels, usePrefs } from 'wsm-common-data';

import useFocusTrap from '../../hooks/modern/useFocusTrap';
import VehicleCards from './VehicleCards';
import SearchLinks from './SearchLinks';
import NoResults from './NoResults';
import Chip from './Chip';

const DropDown = ({
	dropDownRef = React.createRef(),
	inputRef,
	closeButtonRef,
	isOpen,
	recentSearches,
	dropDownPosition,
	inputWidth,
	onRemove,
	handleClearHistory,
	onReorder,
	inventoryResults,
	pageResults,
	searchTerm,
	numberOfCharactersForFirstSearchNumber
}) => {
	const { suggestions = undefined, vehicles = undefined } =
		inventoryResults || {};
	const labels = useLabels();
	const prefs = usePrefs();
	const chipAriaLabel = labels.get('SEARCH');
	const removeAriaLabel = labels.get('REMOVE');
	const hasResults =
		pageResults?.length > 0 ||
		suggestions?.length > 0 ||
		vehicles?.length > 0;

	const hasEmptyResults =
		(Array.isArray(pageResults) && pageResults?.length === 0) ||
		(Array.isArray(suggestions) && suggestions?.length === 0) ||
		(Array.isArray(vehicles) && vehicles?.length === 0);

	useFocusTrap([inputRef, dropDownRef], closeButtonRef, isOpen);

	const classNames = [
		'modern-search-dropdown',
		'box',
		'p-4',
		!isOpen && 'hidden'
	];

	if (recentSearches?.length === 0 && !searchTerm) {
		return null;
	}

	const dropDownContent = (
		<div
			ref={dropDownRef}
			className={setClassNames(classNames)}
			data-testid="modern-input-dropdown"
			role="dialog"
			aria-labelledby={
				searchTerm ? 'results-heading' : 'recent-searches-heading'
			}
			aria-modal="true"
			style={{
				position: 'absolute',
				top: dropDownPosition.top,
				left: dropDownPosition.left,
				width: inputWidth
			}}
		>
			{searchTerm?.length >= numberOfCharactersForFirstSearchNumber &&
				!hasResults &&
				hasEmptyResults && <NoResults searchTerm={searchTerm} />}

			{(!searchTerm ||
				searchTerm?.length < numberOfCharactersForFirstSearchNumber) &&
				recentSearches?.length > 0 && (
					<div>
						<div className="d-flex justify-content-between align-items-center">
							<h2
								id="recent-searches-heading"
								className="ddc-heading-3 font-weight-bold mt-0"
							>
								{labels.get('RECENT_SEARCHES')}
							</h2>
							<button
								className="btn btn-link p-0 text-danger"
								style={{
									cursor: 'pointer',
									fontWeight: 'lighter',
									fontSize: '0.8rem'
								}}
								onClick={handleClearHistory}
								type="button"
								aria-label={labels.get('CLEAR_SEARCH_HISTORY')}
							>
								{labels.get('CLEAR_HISTORY')}
							</button>
						</div>
						{recentSearches?.slice(0, 5).map((recentSearch) => (
							<Chip
								key={recentSearch.id}
								recentSearch={recentSearch}
								btnLabel={chipAriaLabel}
								removeLabel={removeAriaLabel}
								onRemove={onRemove}
								onReorder={onReorder}
							/>
						))}
					</div>
				)}
			{searchTerm?.length >= numberOfCharactersForFirstSearchNumber &&
				hasResults && (
					<div>
						<h2 id="results-heading" className="sr-only">
							{labels.get('SEARCH_RESULTS')}
						</h2>
						<div className="row">
							<div className="col-lg-6">
								<VehicleCards vehicles={vehicles} />
							</div>
							<div className="col-lg-6">
								<SearchLinks
									type="inventory"
									showResults={prefs.showInventoryResults}
									data={suggestions}
									iconClass="ddc-icon-single-vehicle"
									headingLabel={labels.get(
										'INVENTORY_SEARCH_RESULTS'
									)}
									ariaLabel={labels.get(
										'INVENTORY_SEARCH_RESULTS'
									)}
								/>
								<SearchLinks
									type="page"
									showResults={prefs.showPageSearchResults}
									data={pageResults}
									iconClass="ddc-icon-arrow-circle-right"
									headingLabel={labels.get(
										'PAGE_SEARCH_RESULTS'
									)}
									ariaLabel={labels.get('PAGE')}
								/>
							</div>
						</div>
					</div>
				)}
		</div>
	);
	return ReactDOM.createPortal(dropDownContent, document.body);
};

DropDown.propTypes = {
	dropDownRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	closeButtonRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	isOpen: PropTypes.bool.isRequired,
	recentSearches: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			id: PropTypes.string
		})
	),
	dropDownPosition: PropTypes.shape({
		top: PropTypes.number.isRequired,
		left: PropTypes.number.isRequired
	}).isRequired,
	inputWidth: PropTypes.number.isRequired,
	onRemove: PropTypes.func,
	handleClearHistory: PropTypes.func,
	onReorder: PropTypes.func,
	inventoryResults: PropTypes.shape({}),
	pageResults: PropTypes.arrayOf(PropTypes.shape({})),
	searchTerm: PropTypes.string,
	numberOfCharactersForFirstSearchNumber: PropTypes.number
};

export default DropDown;
