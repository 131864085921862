import React, { useState, Suspense, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRequestData, usePrefs, useFlags } from 'wsm-common-data';
import { ErrorBoundary } from 'wsm-error-boundary';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import logger from 'ws-scripts/modules/logger';
import { isBrowser } from 'ws-scripts/modules/environment';
import ModernContainer from './ModernContainer';
import InputDisplayButton from '../components/InputDisplayButton';
import SearchButton from '../components/SearchButton';
import SearchModal from '../components/SearchModal';
import TrackingEvent from '../util/trackingEvent';
import SpeechRecognitionWrapper from '../util/SpeechRecognitionWrapper';
import { g } from '../global-constants';
import '../global-sass/main.scss';

const SearchForm = React.lazy(() =>
	import(
		/* webpackChunkName: "SearchForm" , webpackPrefetch: true */ '../components/SearchForm'
	)
);

const Widget = () => {
	if (typeof window !== 'undefined') {
		window.DDC = window.DDC || {};
		window.DDC.WidgetData = window.DDC.WidgetData || {};
	}
	const { windowId, widgetName, deviceType } = useRequestData();
	const { display, hideWidgetOnPages } = usePrefs();
	const {
		widgetData: { pageAlias }
	} = useSelector((state) => state);

	const { triggerTrackingEvent } = new TrackingEvent(windowId, widgetName);
	const speechRecognitionWrapper = new SpeechRecognitionWrapper();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const inputRef = React.useRef();
	const searchButtonRef = React.useRef();
	const isMobile = deviceType === g.MOBILE;
	const flags = useFlags();

	const openModal = useCallback(() => {
		if (
			!modalIsOpen &&
			!window?.DDC?.WidgetData?.[widgetName]?.modalIsOpen
		) {
			window.DDC.WidgetData[widgetName] = {
				modalIsOpen: true
			};
			triggerTrackingEvent({
				element: 'search box',
				result: 'search modal initiated',
				action: 'clicked'
			});
			setNewRelicCustomAttribute('wsSiteTextSearch-modalOpened', 'true');
			setModalIsOpen(true);
		}
	}, [modalIsOpen, widgetName]);

	const closeModal = (closeMethod = 'background overlay') => {
		window.DDC.WidgetData[widgetName] = {
			modalIsOpen: false
		};
		setModalIsOpen(false);
		triggerTrackingEvent({
			element: closeMethod,
			result: 'search modal closed'
		});
		// For the navigation portal implementation on mobile,
		// closing the modal should remove the 'active' class from the navbar button
		if (isMobile && display === g.NAVIGATION && searchButtonRef?.current) {
			searchButtonRef.current.classList.remove('active');
		}
	};

	useEffect(() => {
		document.addEventListener('ws-site-text-search-open-modal', openModal);
		return () =>
			document.removeEventListener(
				'ws-site-text-search-open-modal',
				openModal
			);
	}, [openModal]);

	if (
		hideWidgetOnPages
			.split(',')
			.some((aliasToHideOn) => aliasToHideOn.trim() === pageAlias)
	) {
		return null;
	}

	const searchForm = isBrowser ? (
		<Suspense fallback={<div />}>
			<SearchForm
				inputRef={inputRef}
				triggerTrackingEvent={triggerTrackingEvent}
				speechRecognitionWrapper={speechRecognitionWrapper}
				closeModal={closeModal}
			/>
		</Suspense>
	) : (
		<div />
	);

	const searchModal = (
		<SearchModal
			isOpen={modalIsOpen}
			onClose={closeModal}
			inputRef={inputRef}
			searchButtonRef={searchButtonRef}
			triggerTrackingEvent={triggerTrackingEvent}
		>
			{modalIsOpen && searchForm}
		</SearchModal>
	);

	let displayMarkup = null;

	switch (display) {
		case g.INPUT:
			if (flags['enable-modern-site-text-search'] === true && !isMobile) {
				displayMarkup = <ModernContainer />;
			} else {
				displayMarkup = (
					<React.Fragment>
						<InputDisplayButton
							openModal={openModal}
							triggerTrackingEvent={triggerTrackingEvent}
							speechRecognitionWrapper={speechRecognitionWrapper}
							type={g.INPUT}
						/>
						{searchModal}
					</React.Fragment>
				);
			}
			break;
		case g.EMBEDDED:
			displayMarkup = searchForm;
			break;
		case g.DISABLED:
		case g.BUTTON:
		case g.NAVIGATION:
		default:
			displayMarkup = (
				<React.Fragment>
					<SearchButton
						searchButtonRef={searchButtonRef}
						onClick={(event) => {
							event.preventDefault();
							openModal();
						}}
					/>
					{searchModal}
				</React.Fragment>
			);
	}

	return (
		<ErrorBoundary
			errorHandler={(error, errorInfo) => {
				const newError = new Error(
					`Encountered an error in ws-site-text-search.\n${error}`
				);
				newError.originalError = error;
				newError.originalStackTrace = errorInfo.componentStack;
				logger.error(`${newError}\n${newError.originalStackTrace}`);
			}}
		>
			{displayMarkup}
			{display === g.INPUT && (
				<div
					data-location="site-text-search"
					className="ddc-content web-api-added-placeholder text-center"
				/>
			)}
		</ErrorBoundary>
	);
};

export default Widget;
