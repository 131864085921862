import React from 'react';
import PropTypes from 'prop-types';
import { useLabels } from 'wsm-common-data';

const NoResults = ({ searchTerm }) => {
	const labels = useLabels();
	const message = labels.get('NO_MATCHES_FOUND_FOR');

	return (
		<div
			data-testid="modern-no-results"
			aria-live="polite"
			aria-atomic="true"
			role="alert"
		>
			<div className="p-4 text-body">
				{message} <strong>{searchTerm}</strong>
			</div>
		</div>
	);
};

NoResults.propTypes = {
	searchTerm: PropTypes.string
};

export default NoResults;
