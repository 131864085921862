import { useEffect, useState } from 'react';

const useFocusTrap = (containerRefs, closeButtonRef, isOpen) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	useEffect(() => {
		if (!isOpen) return;
		const handleKeyDown = (event) => {
			const focusableElements = containerRefs.flatMap((ref) =>
				Array.from(
					ref.current?.querySelectorAll(
						'a, button, input:not([type="hidden"]), [tabindex]:not([tabindex="-1"])'
					) || []
				)
			);
			if (focusableElements.length === 0) return;
			if (event.key === 'Tab') {
				event.preventDefault();
				let newIndex = currentIndex;

				if (event.shiftKey) {
					// Shift + Tab
					newIndex =
						(currentIndex - 1 + focusableElements.length) %
						focusableElements.length;
				} else {
					// Tab
					newIndex = (currentIndex + 1) % focusableElements.length;
				}

				focusableElements[newIndex].focus();
				setCurrentIndex(newIndex);
			} else if (event.key === 'Escape') {
				closeButtonRef.current.click();
			}
		};

		const containers = containerRefs
			.map((ref) => ref.current)
			.filter((container) => container !== null);

		containers.forEach((container) => {
			container.addEventListener('keydown', handleKeyDown);
		});

		// Cleanup function
		// eslint-disable-next-line consistent-return
		return () => {
			containers.forEach((container) => {
				container.removeEventListener('keydown', handleKeyDown);
			});
		};
	}, [containerRefs, closeButtonRef, isOpen, currentIndex]);

	return null;
};

export default useFocusTrap;
