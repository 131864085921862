import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRequestData, usePrefs, useLabels } from 'wsm-common-data';
import { useSelector } from 'react-redux';
import { setClassNames } from 'ddc-classnames-js';
import generateUID from '../../util/modern/generateUID';
import { g } from '../../global-constants';

const InputDisplay = ({
	inputRef = React.createRef(),
	closeButtonRef = React.createRef(),
	openModal,
	isOpen,
	onClose,
	type,
	handleSubmission,
	searchTerm,
	handleOnChangeInput
}) => {
	const labels = useLabels();
	const { searchPlaceholderLabel } = usePrefs();
	const { windowId } = useRequestData();

	const { pageAlias } = useSelector((state) => state.widgetData);

	const inputId = useMemo(
		() => generateUID(`${pageAlias}_${windowId}`),
		[pageAlias, windowId]
	);

	const inputClassNames = [
		'form-control',
		'site-text-search-input',
		'font-size-ios-zoom-override',
		'form-control-transparent',
		'pl-0'
	];
	const closeClassNames = [
		'btn-unstyled text-link-muted',
		'py-3',
		'px-4',
		'border-neutral-0-alpha-3',
		!isOpen && 'hidden'
	];

	return (
		<>
			<div
				ref={inputRef}
				aria-label={labels.get(searchPlaceholderLabel)}
				data-type={type}
				data-testid="modern-input-display"
			>
				<form
					className="form-control spacing-reset d-flex h-auto"
					autoComplete="off"
					data-form-tracking-id="SITEWIDE_SEARCH"
					data-form-tracking-type="typedSearch"
					role="search"
					onSubmit={(e) => handleSubmission(e)}
				>
					<span
						className="ml-3 mr-3 my-3"
						onKeyDown={(e) =>
							e.key === 'Enter' ? handleSubmission() : null
						}
						onClick={() => handleSubmission()}
						role="button"
						tabIndex="0"
						aria-label={labels.get('SEARCH')}
					>
						<i
							className="ddc-icon ddc-icon-search icon-size-2"
							aria-hidden="true"
						/>
					</span>
					<label
						id="site-search-label"
						htmlFor={inputId}
						className="sr-only"
					>
						{labels.get(searchPlaceholderLabel)}
					</label>
					<input
						id={inputId}
						className={setClassNames(inputClassNames)}
						aria-label={labels.get(searchPlaceholderLabel)}
						placeholder={labels.get(searchPlaceholderLabel)}
						onClick={openModal}
						value={searchTerm}
						onFocus={openModal}
						onChange={(e) => handleOnChangeInput(e.target.value)}
						maxLength={100}
						data-testid="modern-input"
					/>
					<button
						ref={closeButtonRef}
						type="button"
						tabIndex="0"
						aria-label={labels.get('CLOSE_MODAL')}
						className={setClassNames(closeClassNames)}
						onClick={() => onClose(g.CLOSE_BUTTON)}
						data-testid="close-button"
					>
						<i
							className="ddc-icon ddc-icon-remove2 icon-size-2"
							aria-hidden="true"
						/>
					</button>
				</form>
			</div>
		</>
	);
};

InputDisplay.propTypes = {
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	closeButtonRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.shape() })
	]),
	openModal: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	type: PropTypes.string,
	handleSubmission: PropTypes.func,
	searchTerm: PropTypes.string,
	handleOnChangeInput: PropTypes.func
};

export default InputDisplay;
